/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// gatsby-browser.js
import React from 'react'
import fetch from 'cross-fetch'
import 'normalize.css'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { LanguageProvider } from './src/context/LanguageContext'

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.action === 'PUSH') {
    window.scrollTo(0, 0)
  }
  return false
}

const cache = new InMemoryCache()
const link = new HttpLink({
  /* Set the endpoint for your GraphQL server, (same as in gatsby-config.js) */
  uri: process.env.SOURCE_WORDPRESS_URL,
  /* Use fetch from cross-fetch to provide replacement for server environment */
  fetch,
})

const client = new ApolloClient({
  link,
  cache,
})
export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <LanguageProvider>{element} </LanguageProvider>
    </ApolloProvider>
  )
}
