import React, { useState, useEffect, createContext } from 'react'

export const LanguageContext = createContext({
  language: 'sv',
  setLanguage: () => {},
})

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('sv')

  useEffect(() => {
    if (window !== undefined) {
      const path = window.location.pathname

      const languageCode = path.split('/')[1]
      if (languageCode === 'en') {
        setLanguage(languageCode)
      } else {
        setLanguage('sv')
      }
    }
  }, [])

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}
